<template>
  <div>
    <modal-confirmation
      v-model="modalConfirmation"
      :item="modalConfirmationItem"
      @result="updateStatus"
    />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Pegawai
              </span>
            </h3>
            <div class="card-toolbar">
              <span
                @click="confirmationDialog()"
                class="btn btn-warning font-weight-bolder font-size-sm mr-2"
              >
                Force Update Password</span
              >
              &nbsp;
              <router-link
                :to="`/employee/create`"
                href="#"
                class="btn btn-success font-weight-bolder font-size-sm"
              >
                Tambah Pegawai</router-link
              >
            </div>
          </div>
          <div class="card-header border-0">
            <DropdownLevel @selected="getHakAkses" styles="w-100" />
          </div>
          <div class="card-header border-0">
            <div class="d-flex justify-start" style="gap: 12px">
              <div class="badge pt-5 px-5 bg-info text-white">
                <span class="font-weight-bolder font-size-sm">Aktif</span>
                <p class="font-weight-bolder mt-2" style="font-size: 2rem">
                  {{ activeEmployee }}
                </p>
              </div>
              <div class="badge pt-5 px-5 bg-danger text-white">
                <span class="font-weight-bolder font-size-sm">Tidak Aktif</span>
                <p class="font-weight-bolder mt-2" style="font-size: 2rem">
                  {{ nonActiveEmployee }}
                </p>
              </div>
              <div class="badge pt-5 px-5 bg-warning text-white">
                <span class="font-weight-bolder font-size-sm"
                  >Menunggu Konfirmasi</span
                >
                <p class="font-weight-bolder mt-2" style="font-size: 2rem">
                  {{ confirmationEmployee }}
                </p>
              </div>
            </div>
          </div>
          <div class="card-header border-0 mt-5">
            <b-form-group>
              <label>status:</label>
              <b-form-select
                id="per-page-select"
                v-model="status"
                :options="statusOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </div>

          <div class="card-body pt-4">
            <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              ref="tables"
              :filter="filter"
              @filtered="onFiltered"
              :items="items"
              :fields="fields"
              :busy="isLoading"
              show-empty
              class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
              responsive
            >
              <template #empty="scope">
                <h6 class="text-center">Tidak ada Pegawai ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada Pegawai ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>

              <template #cell(name)="row">
                <div>
                  {{ row.item.name }} <br />
                  <span
                    class="badge mb-1"
                    :class="{
                      'badge-success':
                        row.item.status == 1 || row.item.status === undefined,
                      'badge-danger': row.item.status == 0,
                      'badge-warning': row.item.status == 2,
                    }"
                    >{{
                      row.item.status == 0
                        ? "Non Aktif"
                        : row.item.status == 1
                        ? "Aktif"
                        : row.item.status == 2
                        ? "Menunggu Approval reaktivasi"
                        : "Aktif"
                    }}</span
                  >&nbsp;
                  <span class="badge badge-secondary">{{
                    row.item.role == "student" ? "Pegawai" : "Staff"
                  }}</span>
                </div>
              </template>

              <template #cell(level_1)="row">
                <template v-if="row.item.level_1.length > 0">
                  <div
                    class="m-1 badge bg-info text-white"
                    v-for="element in row.item.level_1"
                    :key="element._id"
                  >
                    {{ element.name }}
                  </div>
                </template>
                <span v-else>-</span>
              </template>

              <template #cell(level_2)="row">
                <template v-if="row.item.level_2.length > 0">
                  <div
                    class="m-1 badge bg-info text-white"
                    v-for="element in row.item.level_2"
                    :key="element._id"
                  >
                    {{ element.name }}
                  </div>
                </template>
                <span v-else>-</span>
              </template>

              <template #cell(level_3)="row">
                <template v-if="row.item.level_3.length > 0">
                  <div
                    class="m-1 badge bg-info text-white"
                    v-for="element in row.item.level_3"
                    :key="element._id"
                  >
                    {{ element.name }}
                  </div>
                </template>
                <span v-else>-</span>
              </template>

              <template #cell(level_4)="row">
                <template v-if="row.item.level_4.length > 0">
                  <div
                    class="m-1 badge bg-info text-white"
                    v-for="element in row.item.level_4"
                    :key="element._id"
                  >
                    {{ element.name }}
                  </div>
                </template>
                <span v-else>-</span>
              </template>

              <template #cell(level_5)="row">
                <template v-if="row.item.level_5.length > 0">
                  <div
                    class="m-1 badge bg-info text-white"
                    v-for="element in row.item.level_5"
                    :key="element._id"
                  >
                    {{ element.name }}
                  </div>
                </template>
                <span v-else>-</span>
              </template>

              <template #cell(activation)="row">
                <template v-if="(activation = row.item.activation_log)">
                  <span
                    class="badge mb-1"
                    :class="{
                      'badge-danger': activation.to_status == 0,
                      'badge-warning': activation.to_status == 2,
                      'badge-success': activation.to_status == 1,
                    }"
                    >{{
                      activation.to_status == 0
                        ? `Non Aktif`
                        : activation.to_status == 1
                        ? "Aktif"
                        : activation.to_status == 2
                        ? "Menunggu Approval reaktivasi"
                        : "Aktif"
                    }}</span
                  >
                  <br />
                  <span class="text-dark-75 font-size-sm"
                    >{{ `Permintaan Pada Tanggal` }}
                    {{ activation.date | moment("LL HH:mm") }}
                    <br />
                    oleh :
                    <strong>
                      {{ activation.ref_action_by?.name ?? "-" }}</strong
                    ></span
                  >
                </template>
                <span v-else>-</span>
              </template>
              <template #cell(action)="data">
                <b-dropdown
                  variant="light"
                  toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon"
                  no-caret
                  right
                  no-flip
                >
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <div
                        class="navi-link cursor-pointer"
                        @click="goToDetail(data.item)"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-edit"></i>
                        </span>
                        <span class="navi-text">Edit Pegawai</span>
                      </div>
                    </b-dropdown-text>
                    <b-dropdown-text
                      tag="div"
                      class="navi-item"
                      v-if="data.item.status == 1"
                    >
                      <span
                        class="navi-link cursor-pointer bg-danger rounded text-white"
                        @click="onChangeStatus(data.item)"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-exclamation text-white"></i>
                        </span>
                        <span class="navi-text text-white"
                          >Non Aktifkan Pegawai</span
                        >
                      </span>
                    </b-dropdown-text>
                    <b-dropdown-text
                      tag="div"
                      class="navi-item"
                      v-if="
                        data.item.status == 0 ||
                        (isAdmin && data.item.status == 2)
                      "
                    >
                      <span
                        class="navi-link cursor-pointer bg-primary rounded"
                        @click="onChangeStatus(data.item)"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-refresh text-dark"></i>
                        </span>
                        <span class="navi-text text-dark">{{
                          data.item.status == 0
                            ? "Reaktivasi Pegawai"
                            : "Persetujuan Reaktivasi Pegawai"
                        }}</span>
                      </span>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
          <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        </div>
      </div>
    </div>
    <b-modal
      v-model="modalImport"
      id="modal-update"
      cancel-variant="outline-secondary"
      ok-title="Konfirmasi"
      cancel-title="Batalkan"
      centered
      @ok="importFile"
      title="Import Data Kategori"
    >
      <validation-observer ref="formImport">
        <b-form id="form-upload-document" @submit.prevent="importFile">
          <div class="d-flex flex-row mb-1">
            <b-button
              type="button"
              @click="downloadTemplate"
              class="btn btn-fh btn-light-info btn-hover-info border border-info font-weight-bold px-2 px-lg-5"
            >
              <span class="svg-icon svg-icon-info svg-icon-lg">
                <!--begin::Svg-->
                <inline-svg src="media/svg/icons/General/Clip.svg" />
              </span>
              Sample Data Pegawai.xlsx
            </b-button>
          </div>
          <b-form-group
            id="input-group-3"
            label="File (Excel)"
            label-for="input-3"
          >
            <validation-provider
              #default="{ errors }"
              name="File (Excel)"
              rules="required"
            >
              <b-form-file
                ref="file"
                v-model="fileImport"
                :state="errors.length > 0 ? false : null"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".csv,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import mixin from "@/core/services/Helpers";

import { saveAs } from "file-saver";

import Swal from "sweetalert2";
import DropdownLevel from "@/view/pages/summary/components/DropdownLevel.vue";

import {
  GET_USER_LIST,
  ROLE_STUDENT,
  DELETE_STUDENT,
  GET_STUDENT_TEMPLATE,
  IMPORT_USER,
  UPDATE_STATUS,
  NOTIFY_RESET_PASSWORD,
} from "@/core/services/store/user.module";

import { USER_SELECTED } from "@/core/services/store/auth.module";
import ModalConfirmation from "./components/ModalConfirmation.vue";

export default {
  name: "SiswaList",
  mixins: [mixin],
  components: {
    ModalConfirmation,
    DropdownLevel,
  },
  data() {
    return {
      modalConfirmation: false,
      modalConfirmationMessage: "",
      modalConfirmationItem: null,
      fileImport: null,
      timeoutFilter: null,
      modalImport: false,
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      status: null,
      statusOptions: [
        { value: null, text: "Semua" },
        { value: 1, text: "Aktif" },
        { value: 0, text: "Tidak Aktif" },
        { value: 2, text: "Menunggu Konfirmasi" },
      ],
      filter: null,
      accessFilter: [],
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "name",
          label: "Nama Pegawai",
          sortable: true,
        },
        {
          key: "username",
          label: "Username",
          sortable: true,
        },
        {
          key: "level_1",
          label: "Direktorat",
          sortable: false,
        },
        {
          key: "level_2",
          label: "Divisi/Region",
          sortable: false,
        },
        {
          key: "level_3",
          label: "Departemen/Cabang",
          sortable: false,
        },
        {
          key: "level_4",
          label: "Supervisor",
          sortable: false,
        },
        {
          key: "level_5",
          label: "Staff",
          sortable: false,
        },
        {
          key: "activation",
          label: "Aktivasi",
          sortable: false,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      items: [],
      activeEmployee: 0,
      nonActiveEmployee: 0,
      confirmationEmployee: 0,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Daftar Pegawai" }]);
    this.getData();
  },
  computed: {
    ...mapState({
      errors: (state) => state.user.errors,
    }),
    ...mapGetters(["currentUserList"]),
    currentUser() {
      return this.$currentUser();
    },
    isSiswa() {
      return false;
    },
    vendorId() {
      const user = this.$store.getters.authUser;
      return this.getVendorId(user);
    },
    isAdmin() {
      return this.currentUser.role == "vendor";
    },
  },
  watch: {
    perPage: function () {
      this.getData();
    },
    currentPage: function () {
      this.getData();
    },
    filter: function () {
      this.isLoading = true;
      clearTimeout(this.timeoutFilter);
      this.timeoutFilter = setTimeout(() => {
        this.getData();
      }, 1500);
    },
    status: function () {
      this.getData();
    },
  },
  methods: {
    goToDetail(item) {
      this.$store.dispatch(USER_SELECTED, item);
      this.$router.push(`/employee/${item.student._id}/edit`);
    },
    downloadTemplate() {
      this.isLoading = true;
      const params = new URLSearchParams();
      params.append("vendor_id", this.vendorId);

      window.location.href = `${GET_STUDENT_TEMPLATE}${this.vendorId}`;
      this.isLoading = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    onFilteredSiswa(filteredItems, item) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      item.totalRows = filteredItems.length;
      item.currentPage = 1;
    },
    importFile() {
      this.$refs.formImport.validate().then(async (success) => {
        if (success) {
          const overlay = this.$loadingOverlay.show();
          this.$store
            .dispatch(IMPORT_USER, {
              isSiswa: true,
              body: {
                file: this.fileImport,
                vendor_id: this.vendorId,
              },
            })
            .then(() => {
              overlay.hide();
              this.getData();
              Swal.fire({
                title: "",
                text: "Import data Pegawai berhasil!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
            })
            .catch(() => {
              overlay.hide();
              this.$bvToast.toast(this.errors, {
                title: `Gagal Import data Pegawai`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    getData() {
      this.items = [];
      this.isLoading = true;
      const params = new URLSearchParams();

      let body = {
        limit: this.perPage,
        page: this.currentPage,
        where: {
          name: this.filter || "",
        },
        accessFilter: this.accessFilter,
        status: this.status,
      };

      // params.append("limit", this.perPage);
      // params.append("page", this.currentPage);
      // params.append("where['name']", this.filter || "");
      this.$store
        .dispatch(GET_USER_LIST, body)
        .then((response) => {
          this.isLoading = false;
          this.totalRows = response.data.total;
          this.items = response.data.data;
          this.activeEmployee = response.data.active;
          this.nonActiveEmployee = response.data.nonActive;
          this.confirmationEmployee = response.data.waitingConfirmation;
          this.$refs.tables.refresh();
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal memuat pegawai`,
            variant: "danger",
            solid: true,
          });
        });
    },
    setActiveTab(event, item) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      item.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    deleteItem(item) {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin ingin menghapus Pegawai ini?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            this.$store
              .dispatch(DELETE_STUDENT, item.item.student._id)
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil hapus pegawai`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal hapus pegawai`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
    onChangeStatus(item) {
      this.modalConfirmation = true;

      this.modalConfirmationItem = item;

      // this.$bvModal
      //   .msgBoxConfirm(message, {
      //     title: "Konfirmasi",
      //     size: "sm",
      //     buttonSize: "sm",
      //     okVariant: "danger",
      //     okTitle: "Ya",
      //     cancelTitle: "Tidak",
      //     footerClass: "p-2",
      //     hideHeaderClose: false,
      //     centered: true,
      //   })
      //   .then((value) => {
      //     if (value) {
      //       this.isLoading = true;
      //       // this.$store
      //       //   .dispatch(DELETE_STUDENT, item.item.student._id)
      //       //   .then((response) => {
      //       //     this.getData();
      //       //     this.$bvToast.toast(`Berhasil hapus pegawai`, {
      //       //       title: response.message,
      //       //       variant: "success",
      //       //       solid: true,
      //       //     });
      //       //   })
      //       //   .catch(() => {
      //       //     this.isLoading = false;
      //       //     this.$bvToast.toast(this.errors, {
      //       //       title: `Gagal hapus pegawai`,
      //       //       variant: "danger",
      //       //       solid: true,
      //       //     });
      //       //   });
      //     }
      //   })
      //   // eslint-disable-next-line no-unused-vars
      //   .catch((err) => {
      //     // An error occurred
      //   });
    },
    updateStatus(item) {
      this.isLoading = true;
      this.$store
        .dispatch(UPDATE_STATUS, item)
        .then((response) => {
          this.getData();
          this.$bvToast.toast(`Berhasil mengubah status pegawai.`, {
            title: response.message,
            variant: "success",
            solid: true,
          });
        })
        .catch((errors) => {
          this.isLoading = false;
          this.$bvToast.toast(errors, {
            title: `Gagal mengubah status pegawai.`,
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    confirmationDialog() {
      this.$bvModal
        .msgBoxConfirm(
          "Beritahukan semua pegawai untuk segera mengubah password?",
          {
            title: "Konfirmasi",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Ya",
            cancelTitle: "Tidak",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$store
              .dispatch(NOTIFY_RESET_PASSWORD)
              .then((response) => {
                this.isLoading = false;
                this.$bvToast.toast(response.data, {
                  title: `berhasil`,
                  variant: "success",
                  solid: true,
                });
                this.getData();
              })
              .catch((response) => {
                this.isLoading = false;
                this.$bvToast.toast(response.data.message, {
                  title: `Gagal`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        });
    },
    getHakAkses(item) {
      this.accessFilter = item;
      this.getData();
      // this.$refs.recapComponents.getData({
      //   level: item,
      // });
    },
  },
};
</script>

<style scoped></style>
