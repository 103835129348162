<template>
  <div>
    <b-modal centered v-model="modalShow" title="Konfirmasi"
      >{{ message }}
      <template #modal-footer="{ ok, cancel }">
        <button
          @click="cancel()"
          v-b-modal.modal-close_visit
          class="btn btn-info m-1"
        >
          Batal
        </button>
        <button
          @click="result(ok, 'reject')"
          v-b-modal.modal-close_visit
          class="btn btn-outline-danger m-1"
          v-if="item.status == 2"
        >
          Tidak Setuju
        </button>
        <button
          @click="result(ok, 'approve')"
          v-b-modal.modal-close_visit
          class="btn m-1"
          :class="{
            'btn-primary': item.status == 0 || item.status == 2,
            'btn-danger': item.status == 1,
          }"
        >
          {{
            item.status == 1
              ? "Non Aktifkan"
              : item.status == 0
              ? "Reaktivasi"
              : "Setuju"
          }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalConfirmation",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  emits: ["update:value"],
  data: () => {
    return {
      modalShow: false,
      message: "",
    };
  },
  computed: {
    currentUser() {
      return this.$currentUser();
    },
    isAdmin() {
      return this.currentUser.role == "vendor";
    },
  },
  watch: {
    modalShow(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.modalShow = newVal;
    },
    item(newVal) {
      if (newVal != null) {
        let item = newVal;
        let message;
        switch (item.status) {
          case 2:
            message = `Apakah anda yakin ingin menyetujui Reaktivasi ${item.student.full_name} [NIK : ${item.username}] sebagai Pegawai?`;
            break;
          case 1:
            message = `Apakah anda yakin ingin menon-aktifkan ${item.student.full_name} [NIK : ${item.username}] sebagai Pegawai?`;
            break;
          case 0:
            message = `Apakah anda yakin ingin mengaktifkan kembali ${item.student.full_name} [NIK : ${item.username}] sebagai Pegawai?`;
            break;
        }
        this.message = message;
      }
    },
  },
  methods: {
    result(event, type) {
      event();

      let status = this.item.status;
      if (type == "approve") {
        if (this.item.status == 2 && this.isAdmin) {
          status = 1;
        } else {
          if (!this.isAdmin && this.item.status == 0) {
            status = 2;
          } else {
            if (this.item.status == 1) status = 0;
            if (this.item.status == 0) status = 1;
          }
        }
      } else if (type == "reject") {
        status = 0;
      }

      let newItem = { ...this.item };
      newItem.status = status;
      this.$emit("result", newItem);
    },
  },
};
</script>
