<template>
  <div :class="['d-flex justify-content-start', styles]" style="gap: 0.5rem">
    <div
      :class="['custom-select', 'flex-fill']"
      style="width: 100px"
      :tabindex="tabindex"
      @blur="open = false"
      v-for="level in levels"
      :key="level.level"
    >
      <div
        class="selected text-truncate"
        v-if="
          allLevel.filter((x) => x.selected && x.level == level.level).length >
          0
        "
        :class="{ open: level.open }"
        @click="level.open = !level.open"
      >
        <div class="d-flex justify-content-start items-selected">
          <div
            class="bg-secondary text-muted px-2 mr-2 rounded"
            v-for="item in allLevel.filter(
              (x) => x.selected && x.level == level.level
            )"
            :key="item._id"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div
        v-else
        class="selected placeholder text-truncate"
        :class="{ open: level.open }"
        @click="level.open = !level.open"
      >
        Pilih {{ level.name }}
      </div>
      <div class="items border-color" :class="{ selectHide: !level.open }">
        <template v-if="level.level == 1">
          <div
            v-for="e in allLevel.filter((x) => x.level == level.level)"
            :key="e._id"
          >
            <AksesCheckbox
              :item="e"
              @onChange="onChangeItem"
              v-model="e.selected"
              :text="e.name"
              css="my-4 mx-4"
              :key="e._id"
            />
          </div>
        </template>
        <template v-else>
          <template
            v-if="allLevel.filter((x) => x.level == level.level - 1).length > 0"
          >
            <div
              v-for="parent in allLevel.filter(
                (x) => x.level == level.level - 1
              )"
              :key="parent._id"
            >
              <div
                v-if="
                  allLevel.filter((x) => x.parent_id == parent._id).length > 0
                "
                class="bg-secondary px-3 py-1"
                :key="parent._id"
              >
                <span class="text-dark font-weight-bold">
                  {{ parent.name }}</span
                >
              </div>
              <AksesCheckbox
                :item="child"
                @onChange="onChangeItem"
                v-for="child in allLevel.filter(
                  (x) => x.parent_id == parent._id
                )"
                v-model="child.selected"
                :text="child.name"
                css="my-4 mx-4"
                :key="child._id"
              />
            </div>
          </template>
          <div class="text-center my-2" v-else>
            Pilih {{ levels.find((x) => x.level == level.level - 1).name }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AksesCheckbox from "../../hak-akses/components/AksesCheckbox.vue";
import { GET_LEVEL_ACCESS } from "@/core/services/store/level.module";
export default {
  components: {
    AksesCheckbox,
  },
  props: {
    initValue: Array,
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    placeholder: {
      type: String,
    },
    styles: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selected: this.default ? this.default : "",
      open: false,
      isSelected: false,
      allLevel: [],
      levels: [
        {
          level: 1,
          open: false,
          name: "Direktorat",
        },
        {
          level: 2,
          open: false,
          name: "Divisi",
        },
        {
          level: 3,
          open: false,
          name: "Departemen",
        },
        {
          level: 4,
          open: false,
          name: "Supervisor",
        },
        {
          level: 5,
          open: false,
          name: "Staff",
        },
      ],
      levelName: ["Direktorat", "Divis", "Departemen", "Supervisor", "Staff"],
    };
  },
  beforeMount() {
    this.getData();
  },
  mounted() {
    // this.$emit("input", this.selected);
  },
  watch: {
    allLevel: {
      handler: function (newVal, oldValue) {
        const selected = newVal.filter((x) => x.selected).map((e) => e._id);
        this.$emit("selected", selected);
      },
      deep: true,
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_LEVEL_ACCESS)
        .then((data) => {
          this.isLoading = false;

          this.allLevel = [];
          data.data.map((e) => this.collectedAllLevel(e));

          if (this.initValue !== undefined) {
            const _idSelected = JSON.parse(JSON.stringify(this.initValue));

            if (_idSelected) {
              this.allLevel.map((x) => {
                var isExist = false;

                _idSelected.map((m) => {
                  if (m == x._id) isExist = true;
                });

                if (isExist) {
                  x.selected = true;
                }
              });
            }
          }
        })
        .catch((response) => {
          this.isLoading = false;
          this.$bvToast.toast(response?.data?.message ?? "terjadi kesalahan", {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    collectedAllLevel(item) {
      var selected = false;

      this.allLevel.push({
        _id: item._id,
        name: item.name,
        level: item.level,
        parent_id: item.parent_id,
        selected: selected,
      });

      if (item.child !== undefined) {
        item.child.map((e) => this.collectedAllLevel(e));
        return;
      }
    },

    changeChildCheckbox(item, state) {
      this.allLevel
        .filter((x) => x.level == item.level + 1 && x.parent_id == item._id)
        .map((e) => {
          this.changeChildCheckbox(e, state);
          e.selected = state;
          return {
            ...e,
          };
        });
    },
    changeParentCheckBox(item, state) {
      this.allLevel
        .filter((x) => x.level == item.level - 1 && item.parent_id == x._id)
        .map((e) => {
          this.changeParentCheckBox(e, state);
          e.selected = state;
          return {
            ...e,
          };
        });
    },
    onChangeItem(item) {
      this.changeChildCheckbox(item.item, item.state);
      // if (item.state) {
      //   this.changeParentCheckBox(item.item, item.state);
      // }
    },
  },
};
</script>

<style scoped>
.custom-select {
  position: relative;
  text-align: left;
  outline: none;
}

.custom-select .selected {
  background-color: transparent;
  color: black;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  padding-bottom: 1rem;
}

.custom-select .selected.open {
  /* border-radius: 6px 6px 0px 0px; */
}

.custom-select .selected:after {
  position: absolute;
  color: #464e5f;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-select .items {
  z-index: 999999;
  color: black;
  overflow: hidden;
  position: absolute;
  background-color: white;
  left: 0;
  right: 0;
  border-radius: 4px;
  border: 1px solid #ebedf3;
  max-height: 500px;
  overflow-y: auto;
}

.custom-select .items div {
  color: black;
  cursor: pointer;
  user-select: none;
  /* padding-top: 8px; */
  /* padding-bottom: 8px; */
}

.custom-select .items:hover {
  /* background-color: #D6D6E0; */
}

.selectHide {
  display: none;
}

.placeholder {
  color: #b5b5c3 !important;
  font-weight: 500;
}

.items-selected {
  /* Set your desired maximum width here */
  overflow-x: auto;
  /* This will add a horizontal scrollbar if content overflows */
  white-space: nowrap;
}

.close-icon {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.close-icon::before,
.close-icon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #333;
  /* Color of the close icon lines */
  top: 50%;
  transform: translateY(-50%);
}

.close-icon::before {
  transform: rotate(45deg);
}

.close-icon::after {
  transform: rotate(-45deg);
}

@media only screen and (max-width: 767px) {
  /* CSS rules for small screens go here */
  .selected-sm {
    max-width: 10%;
  }
}

/* Medium screens (md) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .selected-md {
    max-width: 10%;
  }
}

/* Large screens (lg) */
@media only screen and (min-width: 992px) {
  .selected-lg {
    max-width: 10%;
  }
}
</style>
