var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['d-flex justify-content-start', _vm.styles],staticStyle:{"gap":"0.5rem"}},_vm._l((_vm.levels),function(level){return _c('div',{key:level.level,class:['custom-select', 'flex-fill'],staticStyle:{"width":"100px"},attrs:{"tabindex":_vm.tabindex},on:{"blur":function($event){_vm.open = false}}},[(
        _vm.allLevel.filter((x) => x.selected && x.level == level.level).length >
        0
      )?_c('div',{staticClass:"selected text-truncate",class:{ open: level.open },on:{"click":function($event){level.open = !level.open}}},[_c('div',{staticClass:"d-flex justify-content-start items-selected"},_vm._l((_vm.allLevel.filter(
            (x) => x.selected && x.level == level.level
          )),function(item){return _c('div',{key:item._id,staticClass:"bg-secondary text-muted px-2 mr-2 rounded"},[_c('span',[_vm._v(_vm._s(item.name))])])}),0)]):_c('div',{staticClass:"selected placeholder text-truncate",class:{ open: level.open },on:{"click":function($event){level.open = !level.open}}},[_vm._v(" Pilih "+_vm._s(level.name)+" ")]),_c('div',{staticClass:"items border-color",class:{ selectHide: !level.open }},[(level.level == 1)?_vm._l((_vm.allLevel.filter((x) => x.level == level.level)),function(e){return _c('div',{key:e._id},[_c('AksesCheckbox',{key:e._id,attrs:{"item":e,"text":e.name,"css":"my-4 mx-4"},on:{"onChange":_vm.onChangeItem},model:{value:(e.selected),callback:function ($$v) {_vm.$set(e, "selected", $$v)},expression:"e.selected"}})],1)}):[(_vm.allLevel.filter((x) => x.level == level.level - 1).length > 0)?_vm._l((_vm.allLevel.filter(
              (x) => x.level == level.level - 1
            )),function(parent){return _c('div',{key:parent._id},[(
                _vm.allLevel.filter((x) => x.parent_id == parent._id).length > 0
              )?_c('div',{key:parent._id,staticClass:"bg-secondary px-3 py-1"},[_c('span',{staticClass:"text-dark font-weight-bold"},[_vm._v(" "+_vm._s(parent.name))])]):_vm._e(),_vm._l((_vm.allLevel.filter(
                (x) => x.parent_id == parent._id
              )),function(child){return _c('AksesCheckbox',{key:child._id,attrs:{"item":child,"text":child.name,"css":"my-4 mx-4"},on:{"onChange":_vm.onChangeItem},model:{value:(child.selected),callback:function ($$v) {_vm.$set(child, "selected", $$v)},expression:"child.selected"}})})],2)}):_c('div',{staticClass:"text-center my-2"},[_vm._v(" Pilih "+_vm._s(_vm.levels.find((x) => x.level == level.level - 1).name)+" ")])]],2)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }